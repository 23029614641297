import { InputGroup, Input, InputLeftElement, Icon, chakra, useColorModeValue, forwardRef, InputRightElement, Box, SystemStyleObject } from '@chakra-ui/react';
import throttle from 'lodash/throttle';
import React from 'react';
import type { ChangeEvent, FormEvent, FocusEvent } from 'react';

import searchIcon from 'icons/search.svg';
import { useScrollDirection } from 'lib/contexts/scrollDirection';
import useIsMobile from 'lib/hooks/useIsMobile';
import ClearButton from 'ui/shared/ClearButton';
import breakpoints from 'theme/foundations/breakpoints';


interface Props {
  onChange: (value: string) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onBlur?: (event: FocusEvent<HTMLFormElement>) => void;
  onFocus?: () => void;
  onHide?: () => void;
  onClear: () => void;
  isHomepage?: boolean;
  value: string;
}
const LARGEST_BREAKPOINT = '1240px';

const SearchBarInput = ({ onChange, onSubmit, isHomepage, onFocus, onBlur, onHide, onClear, value }: Props, ref: React.ForwardedRef<HTMLFormElement>) => {
  const [isSticky, setIsSticky] = React.useState(false);
  const scrollDirection = useScrollDirection();
  const isMobile = useIsMobile();
  const sxContainer: SystemStyleObject = {
    [`@media screen and (min-width: ${breakpoints.lg}) and (max-width: ${LARGEST_BREAKPOINT})`]: { flexDirection: 'column' },
  };

  const handleScroll = React.useCallback(() => {
    if (window.pageYOffset !== 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, []);

  const handleChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  React.useEffect(() => {
    if (!isMobile || isHomepage) {
      return;
    }
    const throttledHandleScroll = throttle(handleScroll, 300);

    window.addEventListener('scroll', throttledHandleScroll);

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
    // replicate componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const bgColor = useColorModeValue('white', 'black');
  const transformMobile = scrollDirection !== 'down' ? 'translateY(0)' : 'translateY(-100%)';

  React.useEffect(() => {
    if (isMobile && scrollDirection === 'down') {
      onHide?.();
    }
  }, [scrollDirection, onHide, isMobile]);

  return (
    <chakra.form
      ref={ref}
      noValidate
      onSubmit={onSubmit}
      onBlur={onBlur}
      onFocus={onFocus}
      w={!isMobile ? "58%" : "100%"}
      // w={{
      //   '@media screen and (max-width: 700px)': {
      //     width: '100%'
      //   },
      //   '@media screen and (min-width: 999px)': {
      //     width: '58%'
      //   },
      // }}
      backgroundColor={bgColor}
      // backgroundColor={isHomepage ? 'white' : bgColor}
      borderRadius={{ base: isHomepage ? 'base' : 'none', lg: 'base' }}
      position={{ base: isHomepage ? 'static' : 'fixed', lg: 'static' }}
      top={{ base: isHomepage ? 0 : 100, lg: 0 }}
      left="0"
      zIndex={{ base: isHomepage ? 'auto' : 'sticky1', lg: 'auto' }}
      paddingX={{ base: isHomepage ? 0 : 4, lg: 0 }}
      paddingTop={{ base: isHomepage ? 0 : 1, lg: 0 }}
      paddingBottom={{ base: isHomepage ? 0 : 4, lg: 0 }}
      boxShadow={scrollDirection !== 'down' && isSticky ? 'md' : 'none'}
      transform={{ base: isHomepage ? 'none' : transformMobile, lg: 'none' }}
      transitionProperty="transform,box-shadow,background-color,color,border-color"
      transitionDuration="normal"
      transitionTimingFunction="ease"
      height={"fit-content"}
      // sxContainer
    >
      <InputGroup size={{ base: isHomepage ? 'md' : 'sm', lg: 'md' }}>
        <InputRightElement w={{ base: isHomepage ? 8 : 8, lg: 8 }} mr={{ base: isHomepage ? 2 : 3, lg: 4 }} h="100%">
          <Box bg={'#0784c3'} w={{base: 8, lg: 8 }} h={{base: isHomepage ? "50%" : "70%", lg: '50%'}} justifyContent={'center'} alignItems={'center'} display={'flex'} borderRadius={8}>
            <Icon as={searchIcon} boxSize={{ base: 3 , lg: 4 }} color={'white'} />
          </Box>
        </InputRightElement>
        <Input
          pr={{ base: isHomepage ? '50px' : '38px', lg: '50px' }}
          sx={{
            '@media screen and (max-width: 999px)': {
              paddingRight: isHomepage ? '60px' : '10px',
              paddingLeft: '10px',
              fontSize:'10px',
            },
            '@media screen and (min-width: 1001px)': {
              paddingRight: '60px',
              paddingLeft: '36px',
            },
          }}
          // placeholder={ isMobile ? 'Search by address / ... ' : 'Search by Address / Txn Hash / Block / Token / Domain Name' }
          placeholder={'Search by Address / Txn Hash / Block / Token / Domain Name'}
          // size={{base: 'xs', lg: 'md'}}
          _placeholder={{color: useColorModeValue('black', 'white')}}
          onChange={handleChange}
          border={isHomepage ? '1px solid' : '2px solid'}
          borderColor={useColorModeValue('gray.200', 'whiteAlpha.200')}
          _focusWithin={{ _placeholder: { color: 'gray.300' } }}
          color={useColorModeValue('black', 'white')}
          value={value}
        />
        {value && (
          <InputRightElement top={{ base: isHomepage ? '18px' : 2, lg: '18px' }} right={20}>
            <ClearButton onClick={onClear} />
          </InputRightElement>
        )}
      </InputGroup>
    </chakra.form>
  );
};

export default React.memo(forwardRef(SearchBarInput));
