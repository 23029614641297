import { Center, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

type Props = {
  icon?: React.FC<React.SVGAttributes<SVGElement>>;
  iconSize?: string;
  text: string;
  url: string;
}

const FooterLinkItem = ({ icon, iconSize, text, url }: Props) => {
  return (
    <Link href={ url } display="flex" alignItems="center" h="30px" variant="secondary" target="_blank" fontSize="xs">
      { icon && (
        <Center minW={ 6 } mr="6px">
          <Icon boxSize={ iconSize || 5 } as={ icon }/>
        </Center>
      ) }
      { text === "Delegate to BscScan" ? (<Text textColor={useColorModeValue('gray.600', 'gray.500')} display={'contents'}>Delegate to BscScan  <Text ml={1} px={1} fontSize='xs' borderRadius={25} width={'55px'} textAlign={'center'} bgColor={'#0784c3'} color={'white'}>Staking</Text></Text>) : text }
    </Link>
  );
};

export default FooterLinkItem;
