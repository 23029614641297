import { Center, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

type Props = {
  icon?: React.FC<React.SVGAttributes<SVGElement>>;
  iconSize?: string;
  text: string;
  url: string;
}

const FooterLinkIcon = ({ icon, iconSize, text, url }: Props) => {
  return (
    <Link href={ url } display="flex" alignItems="center" h="30px"target="_blank" title={ text } fontSize="xs" >
      { icon && (
        <Center minW={ 6 } mr="5px" flexWrap={'wrap'}>
          <Icon boxSize={ iconSize || 5 } as={ icon } color={ useColorModeValue('blackAlpha.900', 'white') }/>
          {/* <Text>{text}</Text> */}
        </Center>
      ) }
      
    </Link>
  );
};

export default FooterLinkIcon;
